
import {defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import TablePagination from "@/components/TablePagination.vue";
import Dialog from "@/modules/dialog.vue";
import request from "@/utils/request";
import SearchForm from "@/modules/search-form.vue";
import {ElForm, ElMessage} from "element-plus";
import store from "@/store";

export default defineComponent({
  components: {
    SearchForm,
    TablePagination,
    Dialog,
  },
  setup() {
    const eform = ref<null | typeof ElForm>();
    const table = ref<null | typeof TablePagination>();

    const arr: any[]=[];
    const f = {
      userId:'',
      userName:'',
      password:'',
      userType:'user',
      verifyStatus:'none',
      trueName:'',
      phone:'',
      landAndWaterBusinessLicense:'',
      landAndWaterTransportationAgencyCertificate:'',
      businessLicense:'',
      about:'',
      rejectReason:'',
      managementCompany:'',
      managementCompanyList:arr,
      workType:''
    };
    let state = reactive({
      departmentList:arr,
      typeList:arr,
      statusList:arr,
      companyList:arr,
      isSuper:true,
      searchForm:{
        verifyStatus:'',
      },
      tableHeight:0,
      showEdit:false,
      isAdd:false,
      title:'',
      form: f,

    });

    onMounted(() => {
      getWindowsHeight();
      getData();


      if(store.userType!='super'){
        state.isSuper=false;
      }
    });
    return {
      ...toRefs(state),
      searchClick,

      editClick,
      deleteClick,
      addClick,
      confirmEdit,
      checkUser,

      table,
      eform,
      rules: {
        // userName:  { required: true, message: "用户名称不能为空", trigger: "blur" },
        // password:  { required: true, message: "密码不能为空", trigger: "blur" },
        // departmentId:  { required: true, message: "部门不能为空", trigger: "blur" },
        // phone:[
        //   {
        //     validator: (rule: any, value: any, callback: any) => {
        //
        //       if (value != '' && !(/^[1][3,4,5,7,8,9][0-9]{9}$/.test(value))) {
        //         callback(new Error("请输入正确的手机格式"));
        //       } else {
        //         callback();
        //       }
        //     },
        //     trigger: "blur",
        //   },
        // ],
        // idCard:[
        //   {
        //     validator: (rule: any, value: any, callback: any)  => {
        //
        //       if (value != '' && !(/^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[\d|X|x]$/.test(value))) {
        //         callback(new Error("请输入正确的身份证格式"));
        //       } else {
        //         callback();
        //       }
        //     },
        //     trigger: "blur",
        //   },
        // ],
      },

    };


    function getData() {
      request({
        url: '/enum',
        method: "GET",
        params: {},
      }).then((res) => {
        const d = (res as Record<string, any>).content;

        state.statusList = d.userVerifyMap;
        state.typeList = d.userTypeMap;
        state.companyList = d.managementCompanyMap;
      });
    }


    function getWindowsHeight(){
      state.tableHeight = document.body.clientHeight-50-50-80-30;
    }
    function searchClick(){
      table.value?.getList();
    }

    function addClick(){
      state.showEdit = true;
      state.isAdd = true;
      state.form = JSON.parse(JSON.stringify(f));
      state.form.userType = 'admin';

      state.title = "新增";
    }

    function editClick(row:any){
      request({
        url: '/admin/query/user',
        method: "GET",
        params: {id:row.id},
      }).then((res) => {
        const d = res as Record<string, any>;

        state.showEdit = true;
        state.isAdd = true;
        state.form = d.content.user;
        state.form.managementCompanyList = state.form.managementCompany.split(',');

        state.title = "编辑";
      });
    }
    function deleteClick(row:any){

      request({
        url: "/api/admin/userDelete",
        method: "POST",
        data: row,
      }).then((res) => {
        const d = res as Record<string, any>;
        ElMessage.success(d.text);

        table.value?.getList();
      });
    }

    function confirmEdit(){
      eform.value?.validate((valid: boolean) => {
        if (valid) {

          if(state.title == "新增"||state.title == "编辑"){
            state.form.managementCompany = state.form.managementCompanyList.join(',');

            request({
              url:'/admin/register',
              method: "POST",
              data: JSON.stringify(state.form),
              headers:{
                'Content-Type':'application/json'
              }
            }).then((res) => {
              const d = res as Record<string, any>;
              ElMessage.success(d.text);

              state.showEdit = false;
              table.value?.getList();
            });
          }else{

            request({
              url:  '/admin/verify/user',
              method: "POST",
              data: state.form,
            }).then((res) => {
              const d = res as Record<string, any>;
              ElMessage.success(d.text);

              state.showEdit = false;
              table.value?.getList();
            });
          }
        }
      });
    }

    function checkUser(d:any) {
      state.showEdit = true;
      state.isAdd = false;
      state.form = d;
      state.form.userId = d.id;

      state.title = "审核";
    }

  },
});
