import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50ac2fd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_search_form = _resolveComponent("search-form")!
  const _component_circle_plus = _resolveComponent("circle-plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_TablePagination = _resolveComponent("TablePagination")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_search_form, {
      "form-data": _ctx.searchForm,
      handleQuery: _ctx.searchClick
    }, {
      content: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "审核状态",
          prop: "verifyStatus"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio_group, {
              modelValue: _ctx.searchForm.verifyStatus,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchForm.verifyStatus) = $event)),
              onChange: _ctx.searchClick
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusList, (i) => {
                  return (_openBlock(), _createBlock(_component_el_radio, {
                    key: i.value,
                    label: i.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(i.key), 1)
                    ]),
                    _: 2
                  }, 1032, ["label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["form-data", "handleQuery"]),
    _createVNode(_component_TablePagination, {
      ref: "table",
      "list-url": "/admin/query/userList",
      height: _ctx.tableHeight,
      "list-key": 'userList',
      "other-para": _ctx.searchForm,
      border: true,
      "column-titles": [
      '编号','用户名称','用户类型','真实姓名','手机号','备注','审核状态'
    ],
      props: [
      'id','userName','userType','trueName','phone','about','verifyStatus'
    ],
      "custom-column-slots": {userType:'userType',verifyStatus:'verifyStatus'},
      "show-operation": true
    }, {
      header: _withCtx(() => [
        (_ctx.isSuper)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              onClick: _ctx.addClick
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_circle_plus)
                  ]),
                  _: 1
                }),
                _createTextVNode("新增")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      verifyStatus: _withCtx((d) => [
        _createTextVNode(_toDisplayString(_ctx.statusList.find(e=>e.value===d.val)?.key), 1)
      ]),
      userType: _withCtx((d) => [
        _createTextVNode(_toDisplayString(_ctx.typeList.find(e=>e.value===d.val)?.key), 1)
      ]),
      otherAction: _withCtx((d) => [
        (d.val.userType==='user')
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              link: "",
              type: "primary",
              onClick: ($event: any) => (_ctx.checkUser(d.val))
            }, {
              default: _withCtx(() => [
                _createTextVNode("审核")
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              type: "primary",
              link: "",
              onClick: ($event: any) => (_ctx.editClick(d.val))
            }, {
              default: _withCtx(() => [
                _createTextVNode("编辑")
              ]),
              _: 2
            }, 1032, ["onClick"]))
      ]),
      _: 1
    }, 8, ["height", "other-para"]),
    _createVNode(_component_Dialog, {
      show: _ctx.showEdit,
      "onUpdate:show": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.showEdit) = $event)),
      title: _ctx.title,
      confirm: _ctx.confirmEdit,
      width: "80%"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: _ctx.form,
          "label-width": "100px",
          ref: "eform",
          rules: _ctx.rules
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "用户名称",
                      prop: "userName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          disabled: !_ctx.isAdd,
                          modelValue: _ctx.form.userName,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.userName) = $event)),
                          placeholder: "用户名称"
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.isAdd)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 0,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "密码",
                          prop: "password"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              type: "password",
                              modelValue: _ctx.form.password,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.password) = $event)),
                              placeholder: "密码"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 1,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "真实名称",
                          prop: "trueName"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              disabled: true,
                              modelValue: _ctx.form.trueName,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.trueName) = $event)),
                              placeholder: "真实名称"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 2,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "手机号",
                          prop: "phone"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              disabled: true,
                              modelValue: _ctx.form.phone,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.phone) = $event)),
                              placeholder: "手机号"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "用户类型",
                      prop: "userType"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          disabled: true,
                          modelValue: _ctx.form.userType,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.userType) = $event)),
                          placeholder: "用户类型"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeList, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.key,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (!_ctx.isAdd)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 3,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "用户类别" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              disabled: true,
                              modelValue: _ctx.form.workType,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.workType) = $event)),
                              placeholder: "用户类别"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 4,
                      span: 12
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 5,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "身份证(前)" }, {
                          default: _withCtx(() => [
                            (_ctx.form.identityCardFront)
                              ? (_openBlock(), _createBlock(_component_el_image, {
                                  key: 0,
                                  style: {"height":"100px"},
                                  src: _ctx.form.identityCardFront,
                                  "preview-src-list": [_ctx.form.identityCardFront]
                                }, null, 8, ["src", "preview-src-list"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 6,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "身份证(后)" }, {
                          default: _withCtx(() => [
                            (_ctx.form.identityCardBack)
                              ? (_openBlock(), _createBlock(_component_el_image, {
                                  key: 0,
                                  style: {"height":"100px"},
                                  src: _ctx.form.identityCardBack,
                                  "preview-src-list": [_ctx.form.identityCardBack]
                                }, null, 8, ["src", "preview-src-list"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd&&_ctx.form.workType.includes('船'))
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 7,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "经营许可证" }, {
                          default: _withCtx(() => [
                            (_ctx.form.landAndWaterBusinessLicense)
                              ? (_openBlock(), _createBlock(_component_el_image, {
                                  key: 0,
                                  style: {"height":"100px"},
                                  src: _ctx.form.landAndWaterBusinessLicense,
                                  "preview-src-list": [_ctx.form.landAndWaterBusinessLicense]
                                }, null, 8, ["src", "preview-src-list"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd&&_ctx.form.workType.includes('船'))
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 8,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "运输代理证" }, {
                          default: _withCtx(() => [
                            (_ctx.form.landAndWaterTransportationAgencyCertificate)
                              ? (_openBlock(), _createBlock(_component_el_image, {
                                  key: 0,
                                  style: {"height":"100px"},
                                  src: _ctx.form.landAndWaterTransportationAgencyCertificate,
                                  "preview-src-list": [_ctx.form.landAndWaterTransportationAgencyCertificate]
                                }, null, 8, ["src", "preview-src-list"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd&&_ctx.form.workType.includes('船'))
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 9,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "营业执照" }, {
                          default: _withCtx(() => [
                            (_ctx.form.businessLicense)
                              ? (_openBlock(), _createBlock(_component_el_image, {
                                  key: 0,
                                  style: {"height":"100px"},
                                  src: _ctx.form.businessLicense,
                                  "preview-src-list": [_ctx.form.businessLicense]
                                }, null, 8, ["src", "preview-src-list"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd&&_ctx.form.workType.includes('车'))
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 10,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "营运证" }, {
                          default: _withCtx(() => [
                            (_ctx.form.tradingCard)
                              ? (_openBlock(), _createBlock(_component_el_image, {
                                  key: 0,
                                  style: {"height":"100px"},
                                  src: _ctx.form.tradingCard,
                                  "preview-src-list": [_ctx.form.tradingCard]
                                }, null, 8, ["src", "preview-src-list"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd&&_ctx.form.workType.includes('车'))
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 11,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "驾驶证" }, {
                          default: _withCtx(() => [
                            (_ctx.form.driverLicense)
                              ? (_openBlock(), _createBlock(_component_el_image, {
                                  key: 0,
                                  style: {"height":"100px"},
                                  src: _ctx.form.driverLicense,
                                  "preview-src-list": [_ctx.form.driverLicense]
                                }, null, 8, ["src", "preview-src-list"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.isAdd&&_ctx.form.workType.includes('车'))
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 12,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, { label: "行驶证" }, {
                          default: _withCtx(() => [
                            (_ctx.form.vehicleLicense)
                              ? (_openBlock(), _createBlock(_component_el_image, {
                                  key: 0,
                                  style: {"height":"100px"},
                                  src: _ctx.form.vehicleLicense,
                                  "preview-src-list": [_ctx.form.vehicleLicense]
                                }, null, 8, ["src", "preview-src-list"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.isAdd)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 13,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "管理公司",
                          prop: "verifyStatus"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, {
                              modelValue: _ctx.form.managementCompanyList,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.managementCompanyList) = $event)),
                              multiple: "",
                              placeholder: "管理公司"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyList, (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: item.value,
                                    label: item.key,
                                    value: item.value
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "备注" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          disabled: !_ctx.isAdd,
                          type: "textarea",
                          modelValue: _ctx.form.about,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.about) = $event))
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "用户状态",
                      prop: "verifyStatus"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio_group, {
                          modelValue: _ctx.form.verifyStatus,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.verifyStatus) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusList, (i) => {
                              return (_openBlock(), _createBlock(_component_el_radio, {
                                key: i.value,
                                label: i.value
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(i.key), 1)
                                ]),
                                _: 2
                              }, 1032, ["label"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "拒绝理由",
                      prop: "rejectReason"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.form.rejectReason,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.rejectReason) = $event)),
                          placeholder: "拒绝理由"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["show", "title", "confirm"])
  ]))
}